<template>
    <div class="page-404">
        <MainMenu :title="'EDUZO'" />

        <div class="page-404-container container">
            <h1>404</h1>
            <p>Stránka sa nenašla</p>

            <b-button @click="$router.replace({ name: 'PageHome' })" variant="primary" class="mt-5">DOMOV</b-button>
        </div>

        <BodyImageBackground />
    </div>
</template>

<script>
import MainMenu from "@/components/MainMenu";
import BodyImageBackground from "@/components/BodyImageBackground";

export default {
    name: "Page404",
    metaInfo() {
        return {
            title: '404 | ',
        }
    },
    components: {
        MainMenu,
        BodyImageBackground,
    },
}
</script>

<style lang="scss" scoped>
.page-404::v-deep {
    @extend %page-position-bg-image;

    .page-404-container {
        @extend %container-position-bg-image;

        h1 {
            font-size: em(100);
            font-weight: $font-weight-bold;
            margin-top: em(50, 100);
        }
    }
}
</style>